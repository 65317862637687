import React from "react"
import ReactHtmlParser from "react-html-parser"
import Url from "url-parse";
import Img from 'gatsby-image'
import qs from "querystringify"

export default function normalize(content, files) {
  return new ReactHtmlParser(content, {
    transform: function transform(node) {
      if (node.type === 'tag' && node.name === 'img') {
        let url = node.attribs["src"];

        // Enforce secure path to images.
        if (url.includes('http://')) {
          url = url.replace('http://', 'https://');
        }

        let alt = node.attribs["alt"] || '';
        let i = 0;
        for (i = 0; i < files.edges.length; i++) {
          // First, detailed debug so we can see what's going on
          if (files.edges[i].node.relationships.field_media_image &&
              files.edges[i].node.relationships.field_media_image.uri.url === url) {
            
            console.log(`Found matching image: ${url}`);
            console.log(`Image details:`, JSON.stringify({
              hasLocalFile: !!files.edges[i].node.relationships.field_media_image.localFile,
              hasChildImageSharp: !!(files.edges[i].node.relationships.field_media_image.localFile?.childImageSharp),
              hasFluid: !!(files.edges[i].node.relationships.field_media_image.localFile?.childImageSharp?.fluid),
              publicURL: files.edges[i].node.relationships.field_media_image.localFile?.publicURL
            }, null, 2));
            
            // Now check if we have the localFile
            if (files.edges[i].node.relationships.field_media_image.localFile) {
              const localFile = files.edges[i].node.relationships.field_media_image.localFile;
              
              // Try to use childImageSharp if available (for regular images)
              if (localFile.childImageSharp && localFile.childImageSharp.fluid) {
                return <Img 
                  Tag="span" 
                  style={{ display: `flex`}} 
                  fluid={localFile.childImageSharp.fluid} 
                  alt={alt}
                />
              } 
              // Fallback to publicURL if available (for GIFs and other non-processable files)
              else if (localFile.publicURL) {
                return <img 
                  src={localFile.publicURL} 
                  alt={alt} 
                  style={{ 
                    display: 'block', 
                    maxWidth: '100%', 
                    height: 'auto',
                    margin: '0 auto' 
                  }}
                />
              }
              // Ultimate fallback - use original URL
              else {
                return <img 
                  src={url} 
                  alt={alt} 
                  style={{ 
                    display: 'block', 
                    maxWidth: '100%', 
                    height: 'auto',
                    margin: '0 auto' 
                  }}
                />
              }
            }
          }
        }
        
        // If we didn't find a matching image in our files
        console.log(`No matching image found for: ${url}, using original URL`);
        return <img 
          src={url} 
          alt={alt} 
          style={{ display: 'flex', width: '100%', height: 'auto' }}
        />
      }
      else if (node.type === 'tag' && node.name === 'iframe') {
        let url = node.attribs.src;
        if (url.search('http') === -1) {
          if (url.search('//') === -1) {
            url = "//" + url;
          }
          url = "https:" + url;
        }
        let url_parsed = new Url(url)
        if (url_parsed['query']) {
          let parsed_query = qs.parse(url_parsed['query'])
          if ('url' in parsed_query) {
            url = parsed_query['url']


            if (url.search('youtube.com') !== -1) {
              url = url.replace('youtube.com', 'youtube.com/embed')
            }
            else if(url.search('youtu.be') !== -1) {
              url = url.replace('youtu.be', 'youtube.com/embed')
            }
          }
        }

        let id = url.split("/").pop();

        return <iframe src={url}
                       width={node.attribs.width}
                       height={node.attribs.height}
                       id={id}
                       key={id}
                       display="initial"
                       position="relative"
                       allowFullScreen />;
      }

      return undefined;
    }
  });
}